<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="sendInvoicesForm"
        ref="sendInvoicesForm"
        class="w-100 pt-6"
        @submit.prevent="submitSendInvoicesForm"
      >
        <v-col cols="12">
          <v-progress-linear
            v-if="isFetchingClient"
            indeterminate
            absolute
            top
            height="3"
          />
          <ForwardDocument
            :disabled="isFetchingClient"
            type="invoice"
            :contact-emails="contactEmails"
            @update="updateContacts"
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="!contactEmails.length || isFetchingClient"
        type="submit"
        form="sendInvoicesForm"
      >
        {{ label }}
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ForwardDocument from '../Forms/Partials/ForwardDocument'
import rules from '../../utils/validators'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    ForwardDocument,
  },
  mixins: [afterFormSubmitted],
  data() {
    return {
      rules,
      contactEmails: [],
      invoiceIds: [],
      isFetchingClient: false,
    }
  },

  async mounted() {
    this.clearSingleClient()
    this.isFetchingClient = true
    await this.getClient(this.clientId)
    this.isFetchingClient = false
  },

  beforeDestroy() {
    this.clearSingleClient()
  },

  computed: {
    ...mapState({
      clientId: state => state.layout.dialog.item.client.id,
      label: state => state.layout.dialog.data.text,
      selectedInvoices: state => state.invoices.selectedItems,
      isProcessing: state => state.invoice.isProcessing,
    }),
  },
  methods: {
    ...mapActions({
      sendInvoices: 'invoice/sendInvoices',
      getClient: 'client/getSingleClient',
      clearSingleClient: 'client/clearSingleClient',
    }),
    updateContacts(contactEmails) {
      this.contactEmails = contactEmails
    },
    submitSendInvoicesForm() {
      if (this.$refs.sendInvoicesForm.validate()) {
        this.sendInvoices({
          contactEmails: this.contactEmails,
          invoiceIds: this.selectedInvoices,
        })
          .then(() => { this.afterFormSubmitted('Wysłano dokument(y)') })
      }
    },
  },
}
</script>
